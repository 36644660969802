<template>
    <header class="header">
        <div class="header__container">
            <div class="header__container__inner">
                <div class="header__container__inner__logo">
                    <routerLink to="/chooseForm">
                        <img class="header__container__inner__logo__img" src="@/assets/img/logo.svg"
                            alt="Аналитический центр города Нижнего Новгорода" />
                    </routerLink>
                    <routerLink to="/chooseForm">
                        <span class="header__container__inner__logo__text">Аналитический центр <br class="sm-none" />
                            города Нижнего Новгорода</span>
                    </routerLink>
                </div>
                <div class="header__container__inner__social">
                    <a href="https://vk.com/anoacg" target="_blank">
                        <img src="@/assets/img/vk.svg" alt="VK" />
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>

<style scoped lang="scss" src="./style.scss">

</style>