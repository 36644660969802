<template>
  <acgHeader />
  <router-view />
  <acgFooter />
</template>

<style lang="scss">
* {
  padding: 0;
  margin: 0;

  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>

<script>
import acgHeader from "@/components/acgHeader/acgHeader.vue";
import acgFooter from "@/components/acgFooter/acgFooter.vue";

export default {
  components: {
    acgHeader,
    acgFooter,
  }
}</script>