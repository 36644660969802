import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/AuthPage/AuthPage.vue"),
    meta: {
      title: "Вход",
    },
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: () => import("@/views/ForgotPassword/ForgotPassword.vue"),
    meta: {
      title: "Восстановление пароля",
    },
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import("@/views/Registration/Registration.vue"),
    meta: {
      title: "Регистрация",
    },
  },
  {
    path: '/admin_panel',
    name: 'panel',
    component: () => import("@/views/AdminPanel/AdminPanel.vue"),
    meta: {
      title: "Главная панель",
    },
  },
  {
    path: '/main',
    name: 'main',
    component: () => import("@/views/mainPage/mainPage.vue"),
    meta: {
      title: "Дорожная техника",
    },
  },
  {
    path: '/prizma_form',
    name: 'prizma_form',
    component: () => import("@/views/prizmaForm/prizmaForm.vue"),
    meta: {
      title: "Соц. сети",
    },
  },
  {
    path: '/empl_form',
    name: 'empl_form',
    component: () => import("@/views/emplForm/emplForm.vue"),
    meta: {
      title: "Обращения предпринимателей",
    },
  },
  {
    path: '/park_form',
    name: 'park_form',
    component: () => import("@/views/parkForm/parkForm.vue"),
    meta: {
      title: "Парки",
    },
  },
  {
    path: '/change_password',
    name: 'change_password',
    component: () => import("@/views/changePassword/changePassword.vue"),
    meta: {
      title: "Смена пароля",
    },
  },
  {
    path: '/leisure_activities',
    name: 'leisure_activities',
    component: () => import("@/views/leisureActivities/leisureActivities.vue"),
    meta: {
      title: "Досуговая активность",
    },
  },
  {
    path: '/sport',
    name: 'sport',
    component: () => import("@/views/sport/sportPage.vue"),
    meta: {
      title: "Опрос",
    },
  },
  {
    path: '/staff',
    name: 'staff',
    component: () => import("@/views/staff/staff.vue"),
    meta: {
      title: "Кадры",
    },
  },
  {
    path: '/staffMunic',
    name: 'staffMunic',
    component: () => import("@/views/staffMunic/staffMunic.vue"),
    meta: {
      title: "Кадры муниципальных учреждений",
    },
  },
  {
    path: '/chooseForm',
    name: 'chooseForm',
    component: () => import("@/views/chooseForm/chooseForm.vue"),
    meta: {
      title: "Выбор формы для заполнения",
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});


export default router
